import styled from "@emotion/styled";
import { StaticDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Dayjs as DayjsType } from "dayjs";
import * as React from "react";

import { DATE_UNIT } from "~/constants/dates";

export interface Props {
  initialValue: DayjsType;
  onChange: (date: MaterialUiPickersDate) => void;
}

const CustomRangePickerMonth: React.FC<Props> = ({
  initialValue,
  onChange
}) => {
  return (
    <Wrapper className="min-h-[160px] w-[205px] absolute z-10 top-[26px] left-[50px]">
      <StaticDatePicker
        value={initialValue || null}
        onChange={onChange}
        views={[DATE_UNIT.month]}
      />
    </Wrapper>
  );
};

export default CustomRangePickerMonth;

const Wrapper = styled.div`
  > div {
    min-width: 190px;
    min-height: 160px;
    box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.14);
  }
  .MuiPickersToolbar-toolbar {
    display: none;
  }
  .MuiPickersBasePicker-pickerView {
    height: 145px;
    .MuiPickersMonthSelection-container {
      width: 205px;
      .MuiPickersMonth-root {
        font-size: 18px;
        height: 38px;
      }
    }
  }
`;
