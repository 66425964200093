import Skeleton from "@material-ui/lab/Skeleton";
import * as React from "react";

import { SegmentedButtons } from "~/components/core/SegmentedButtons";
import { SkeletonError } from "~/components/core/StatisticCard/StatisticCardSkeleton";
import { useAuthenticatedFetch } from "~/utils/http";
import { trackEvent } from "~/utils/segment";
import { struct } from "~/utils/struct";

import { createDashboardLazyWidget } from "../createDashboardLazyWidget";
import { Ratings } from "./Ratings";

const DashboardStudentRatingsOfTutors = createDashboardLazyWidget({
  displayName: "DashboardStudentRatingsOfTutors",
  contentHeight: 300,
  caption: "Student ratings of tutors",
  className: "flex-[55%] md:flex-[66%] relative",
  captionClassName: "!p-[24px]",
  testid: struct.adminDash.dashboard.ratings.student,
  useRender: apiQuery => {
    const [lessons, setLessons] = React.useState(true);

    const { data, loading, error, refetch } = useAuthenticatedFetch(
      lessons
        ? "/api/v1/new_stats/session_ratings/"
        : "/api/v1/new_stats/writing_lab_ratings/",
      { ...apiQuery, isStudentRatings: true },
      true
    );

    React.useEffect(() => {
      trackEvent(
        lessons
          ? "load_student_ratings_ll_chart"
          : "load_student_ratings_wl_chart",
        undefined
      );
    }, [lessons]);

    const toggle = (on: boolean) => {
      setLessons(on);
      requestAnimationFrame(() => refetch());
      trackEvent(
        lessons ? "st_rating_wl_click" : "st_rating_live_click",
        undefined
      );
    };

    return (
      <>
        <SegmentedButtons
          on={lessons}
          toggle={toggle}
          titlePrimary="Live tutoring"
          titleSecondary="Writing Lab"
          className="flex absolute right-[22px] md:right-[28px] top-[48px] md:top-[28px]"
          testidPrimary={struct.adminDash.dashboard.ratings.student.ll}
          testidSecondary={struct.adminDash.dashboard.ratings.student.wl}
        />

        {error || loading ? (
          <Skeleton
            className="h-[calc(100%-32px)] flex items-center justify-center bg-cream-300 mx-[24px] my-[12px] transform-none rounded-[8px]"
            animation={error ? false : "pulse"}
          >
            {error && (
              <SkeletonError
                isChart
                testid={struct.adminDash.dashboard.ratings.student.noData}
              />
            )}
          </Skeleton>
        ) : (
          <div className="flex gap-[48px] my-[12px] mx-[24px]">
            <Ratings
              lessons={lessons}
              data={data}
              testid={struct.adminDash.dashboard.ratings.student}
            />
          </div>
        )}
      </>
    );
  }
});

export default DashboardStudentRatingsOfTutors;
