import { Button } from "@goguardian/pdt-component-library";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import * as React from "react";

export interface ErrorBoundaryDefaultFallbackProps {
  error: Error;
}

const ErrorBoundaryDefaultFallback: React.FC<ErrorBoundaryDefaultFallbackProps> = ({
  error
}) => {
  return (
    <Paper className="flex h-full ">
      <div className="flex flex-col items-center justify-center">
        <Typography variant="body2">Something went wrong</Typography>
        <Button
          variant="primary"
          className="my-[20px]"
          action={() => location.reload()}
        >
          Reload the page
        </Button>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            Details
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <pre>{JSON.stringify(error, null, " ")}</pre>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    </Paper>
  );
};

export default ErrorBoundaryDefaultFallback;
