import ErrorIcon from "@material-design-icons/svg/filled/error.svg";
import * as React from "react";

import ClarificationIcon from "~/components/core/ClarificationIcon";

import InfoIcon from "../../../assets/icons/menu_alert.svg";

export const ConfidenceChange: React.FC<{ data?: IQuickFacts }> = ({
  data
}) => {
  return (
    <div className="quickFacts-change flex flex-col gap-[8px]">
      <div>
        {(data?.sessionCount ?? 0) < 15 ? (
          <div className="bg-navy-100 text-navy-800 whitespace-nowrap inputLabel gap-[8px] flex items-center rounded-[8px] px-[12px] py-[4px]">
            <InfoIcon className="h-[24px] w-[24px] flex-shrink-0" />
            More data needed
          </div>
        ) : data && data.confidenceLevel >= 0 ? (
          <div className="w-fit bg-kiwi-100 !text-kiwi-800 h2 rounded-[8px] px-[12px] py-[4px]">
            {`+${data?.confidenceLevel}%`}
          </div>
        ) : (
          <div className="flex gap-[8px] items-center explanatoryText !text-redApple-700 whitespace-pre-wrap">
            <ErrorIcon
              aria-hidden
              className="w-[24px] h-[24px] flex-shrink-0 fill-current"
            />
            Something went wrong
            <br />
            and we're looking into it.
          </div>
        )}
      </div>
      <div className="flex">
        <div className="paragraph sm:whitespace-nowrap">
          Change in student <br className="quickFacts-hide" />
          confidence
        </div>
        <ClarificationIcon clarification={confidenceTooltip} />
        <div className="bg-blueSkies-700 rounded-[4px] px-[6px] leading-[22px] text-[14px] font-semibold text-white h-fit ml-[4px]">
          Beta
        </div>
      </div>
    </div>
  );
};

const confidenceTooltip = (
  <div>
    <span className="font-semibold">Change in student confidence</span> is the
    average percent difference between students' pre-session confidence score
    and their post-session confidence score.
  </div>
);
