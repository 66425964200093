import structPackageJSON from "@tutorme/sitestruct/package.json";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import {
  useCopyToClipboard,
  useLocalStorage,
  useMountedState
} from "react-use";

declare global {
  interface Window {
    showTestIds: undefined | (() => void);
  }
}

export const TestIDUtils = () => {
  const isMounted = useMountedState();
  const [shouldRender, setShouldRender] = useState(false);
  const [, copyToClipboard] = useCopyToClipboard();
  const [rects, setRects] = useState<Array<{ rect: DOMRect; name: string }>>(
    []
  );
  const [show, setShow] = useLocalStorage("showTestIds", false);

  useEffect(() => {
    setShouldRender(true);
  }, []);

  useEffect(() => {
    window.showTestIds = () => {
      setShow(!show);
    };
    return () => {
      window.showTestIds = undefined;
    };
  }, [setShow, show]);

  useEffect(() => {
    if (show) {
      const callback = debounce(
        () => {
          if (isMounted()) {
            setRects(
              Array.from(document.querySelectorAll("[data-testid]")).map(
                el => ({
                  rect: el.getBoundingClientRect(),
                  name: el.getAttribute("data-testid") ?? "unknown"
                })
              )
            );
          }
        },
        16,
        { maxWait: 16, trailing: true }
      );
      window.addEventListener("scroll", callback);
      window.addEventListener("resize", callback);
      const observer = new MutationObserver(callback);
      observer.observe(document.body, {
        attributes: true,
        childList: true,
        subtree: true
      });
      callback();
      return () => {
        window.removeEventListener("scroll", callback);
        window.removeEventListener("resize", callback);
        observer.disconnect();
      };
    }
    return undefined;
  }, [show, isMounted]);

  const copy = (name: string) => () => copyToClipboard(name);

  if (!show || !shouldRender) {
    return null;
  }

  return (
    <>
      <div
        className="fixed top-[10px] left-[10px] opacity-50 z-[66666667] p-[4px] text-white bg-gray-500 "
        onClick={copy(structPackageJSON.version)}
      >
        version {structPackageJSON.version}
      </div>
      {rects.map(({ rect, name }, idx) => (
        <div
          className="fixed opacity-50 z-[66666667]"
          key={idx}
          style={{ top: rect.top, left: rect.left }}
        >
          <div
            className="pointer-events-none ring-[-1px] border border-redApple-500 absolute"
            style={{ width: rect.width, height: rect.height }}
          />
          <div
            className="cursor-pointer absolute top-[-21px] left-[-1px] p-[4px] bg-redApple-500 text-[12px] text-white leading-tight select-none"
            style={{ top: rect.top < 30 ? rect.height - 1 : undefined }}
            onClick={copy(name)}
          >
            {name}
          </div>
        </div>
      ))}
    </>
  );
};
