import { Skeleton } from "@material-ui/lab";
import React from "react";

import { PERIOD } from "~/constants/dates";
import { SCALE, SESSION } from "~/constants/filters";
import { DashboardApiQuery } from "~/utils/http";

import {
  useDashboardLazy,
  useDashboardQueryState
} from "./dashboardQueryState";

export type CreateDashboardLazyWidgetPropsNew = {
  displayName: string;
  useRender: (
    apiQuery: DashboardApiQuery,
    resetFilters: () => void
  ) => React.ReactElement | null;
};

export function createDashboardLazyWidgetNew({
  displayName,
  useRender
}: CreateDashboardLazyWidgetPropsNew) {
  const Lazy: React.FC = function Lazy() {
    const [, setQueryState] = useDashboardQueryState();
    const { showContent, apiQuery, ref } = useDashboardLazy(displayName);

    const resetFilters = () => {
      setQueryState({
        subjects: undefined,
        period: PERIOD.last30Days,
        scale: SCALE.daily,
        moderation: SESSION.all,
        fromDt: undefined,
        tillDt: undefined
      });
    };

    const Comp: React.FC<{
      apiQuery: DashboardApiQuery;
      resetFilters: () => void;
    }> = ({ apiQuery, resetFilters }) => {
      return useRender(apiQuery, resetFilters);
    };

    Comp.displayName = displayName;

    return (
      <div
        ref={ref}
        className="flex justify-between py-[12px] items-center graph-container"
      >
        {showContent ? (
          <Comp apiQuery={apiQuery} resetFilters={resetFilters} />
        ) : (
          <Skeleton className="w-full h-[110px]" />
        )}
      </div>
    );
  };

  return Lazy;
}
