import { Button } from "@goguardian/pdt-component-library";
import { useGoogleLogin } from "@react-oauth/google";
import { noop } from "lodash";
import * as React from "react";

import { useShowSnack } from "~/components/layouts/common/SnackLayout";
import { anonymousPost, authenticatedGet } from "~/utils/http";

type LoginFormValues = {
  access_token?: string | undefined;
};

export type LoginFormResult = {
  tokens: [string, string];
  user: IUser;
};

type LoginFormProps = {
  onPopUpOpen?: () => void;
  onSubmit: (result: LoginFormResult) => void;
  onPopUpClosed?: () => void;
};

export const LoginWithGoogle: React.FC<LoginFormProps> = ({
  onSubmit,
  onPopUpOpen,
  onPopUpClosed
}) => {
  const showSnack = useShowSnack();
  const errHandler = React.useCallback(
    (errString = "Error while logging in with Google") => {
      onPopUpClosed && onPopUpClosed();
      showSnack.error(errString);
    },
    [onPopUpClosed, showSnack]
  );

  const submitWrapper = React.useCallback(
    (values: LoginFormValues) =>
      anonymousPost("/api/v1/auth/jwt/create_google/", values)
        .then(response => {
          if (response.status === 200) {
            const tokens = [response.data.access, response.data.refresh] as [
              string,
              string
            ];
            return authenticatedGet(
              "/api/v1/account/me/",
              response.data.access,
              {}
            ).then(user => {
              onSubmit({ tokens, user });
            });
          } else if (response.status === 401) {
            errHandler(
              "You are not allowed to login using Google, please use your email and password."
            );
          } else {
            errHandler(`Http error ${response.status}`);
          }
        })
        .catch(err => {
          errHandler(err.toString() ?? "Unknown error");
        }),
    [onSubmit, errHandler]
  );

  const customLoginHandler = useGoogleLogin({
    onSuccess: resp => submitWrapper(resp),
    onError: () => errHandler(),
    onNonOAuthError: onPopUpClosed
  });

  return (
    <GoogleButton
      action={() => {
        onPopUpOpen && onPopUpOpen();
        customLoginHandler();
      }}
    />
  );
};

export const GoogleButton: React.FC<{ action?: () => void }> = ({ action }) => (
  <Button
    variant="oAuth"
    className="!w-full justify-center"
    action={action ?? noop}
  >
    <img
      src="https://developers.google.com/identity/images/g-logo.png"
      className="w-[18px] h-[18px] absolute left-[69px]"
    />
    <div className="explanatoryText">Sign in with Google</div>
  </Button>
);
