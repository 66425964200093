import { Button, ButtonLink } from "@goguardian/pdt-component-library";
import { noop } from "lodash";
import * as React from "react";
import { FormContext, useForm } from "react-hook-form";
import z from "zod";

import {
  GoogleButton,
  LoginWithGoogle
} from "~/components/auth/common/LoginWithGoogle";
import { Input } from "~/components/core/Input/input";
import LoadingIndicator from "~/components/core/LoadingIndicator";
import { useShowSnack } from "~/components/layouts/common/SnackLayout";
import { PEAR_PRIVACY_URL, PEAR_TERMS_URL } from "~/constants/api";
import ROUTES from "~/constants/routes";
import {
  anonymousPost,
  authenticatedGet,
  convertDrfErrors
} from "~/utils/http";

const formSchema = z.object({
  email: z
    .string()
    .trim()
    .min(1, { message: "This field is required." }),
  password: z
    .string()
    .trim()
    .min(1, { message: "This field is required." })
});

type FormSchema = z.infer<typeof formSchema>;

export type LoginFormResult = {
  tokens: [string, string];
  user: IUser;
};

type LoginFormProps = {
  onSubmit: (result: LoginFormResult) => void;
};

export const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
  const form = useForm<FormSchema>({
    mode: "onBlur",
    defaultValues: {
      email: "",
      password: ""
    }
  });
  const showSnack = useShowSnack();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [pendingGoogleLogin, setPendingGoogleLogin] = React.useState(false);
  const { email, password } = form.watch();

  const googleLoginHandler = (result: LoginFormResult) => {
    onSubmit(result);
  };

  const submit = () => {
    form.triggerValidation().then(isValid => {
      if (isValid) {
        form.handleSubmit(submitWrapper)();
      }
    });
  };

  const submitWrapper = (values: FormSchema) => {
    setIsSubmitting(true);
    anonymousPost("/api/v1/auth/jwt/create/", values)
      .then(response => {
        if (response.status === 200) {
          const tokens = [response.data.access, response.data.refresh] as [
            string,
            string
          ];
          return authenticatedGet(
            "/api/v1/account/me/",
            response.data.access,
            {}
          ).then(user => {
            onSubmit({ tokens, user });
          });
        } else if (response.status === 400) {
          form.setError(convertDrfErrors(response.data));
        } else if (response.status === 401) {
          showSnack.error("Invalid credentials");
        } else {
          showSnack.error(`Http error ${response.status}`);
        }
        setIsSubmitting(false);
      })
      .catch(err => {
        showSnack.error(err.toString() ?? "Unknown error");
        setIsSubmitting(false);
      });
  };

  return (
    <FormContext {...form}>
      <form className="flex flex-col gap-[16px]">
        <h3 className="w-full text-center h2">
          Sign in to the Admin Dashboard
        </h3>

        <div className="w-full h-[1px] bg-cream-300" />

        {!process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID ? (
          <GoogleButton />
        ) : (
          <LoginWithGoogle
            onSubmit={googleLoginHandler}
            onPopUpOpen={() => setPendingGoogleLogin(true)}
            onPopUpClosed={() => setPendingGoogleLogin(false)}
          />
        )}

        <div>
          <div id="psi_sign_in" />
        </div>

        <div className="flex items-center mt-[-43px]">
          <div className="flex-grow h-[1px] bg-cream-300" />
          <div className="bg-cream-100 w-[30px] text-center">or</div>
          <div className="flex-grow h-[1px] bg-cream-300" />
        </div>

        <Input name="email" label="Email" type="email" required />
        <Input name="password" label="Password" type="password" required />

        <ButtonLink
          variant="wrapper"
          className="self-end paragraph link"
          action={noop}
          href={ROUTES.auth.passwordReset}
          disabled={isSubmitting || pendingGoogleLogin}
        >
          Forgot password?
        </ButtonLink>

        <Button
          variant="primary"
          action={submit}
          disabled={
            isSubmitting ||
            pendingGoogleLogin ||
            !email.trim() ||
            !password.trim()
          }
          className="!w-full justify-center"
        >
          Sign in
        </Button>

        <div className="explanatoryText">
          Clicking “Sign in” means you are agreeing to the updated Terms and
          Privacy Policy
        </div>

        <div className="flex flex-col sm:flex-row gap-[16px] sm:gap-[24px]">
          <ButtonLink
            variant="wrapper"
            action={noop}
            target="_blank"
            href={PEAR_TERMS_URL}
            className="paragraph link"
          >
            Product Terms of Service
          </ButtonLink>

          <ButtonLink
            variant="wrapper"
            action={noop}
            target="_blank"
            href={PEAR_PRIVACY_URL}
            className="paragraph link"
          >
            Product Privacy Policy
          </ButtonLink>
        </div>
      </form>
      {(isSubmitting || pendingGoogleLogin) && <LoadingIndicator />}
    </FormContext>
  );
};
