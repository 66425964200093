import { AreaChart, DataSet, Scale, TooltipProps } from "@tutorme/area-chart";
import { format, max, min, parseISO } from "date-fns";
import React from "react";

import { DashboardApiQuery } from "~/utils/http";

import { useRequiredAuthContext } from "../auth/common";

type DashboardAreaChartProps = {
  apiQuery: DashboardApiQuery;
  label: string;
  data?: Array<{ dt: string; value: number }>;
  xTicksCB?: (values: IXAxisLabel[], chartData: IChartData[]) => void;
  cumulative?: boolean;
};

function apiQueryScaleToChartScale(scale: DashboardApiQuery["scale"]): Scale {
  switch (scale) {
    case "hourly":
      return "hour";
    case "daily":
      return "day";
    case "weekly":
      return "week";
    case "monthly":
      return "month";
  }
}

const colorPairs = [
  {
    areaColor: "#A7E8CF",
    lineColor: "#195E44",
    pointBgColor: "#195E44",
    pointHoverBgColor: "#fff"
  },
  {
    areaColor: "#8DD7FF",
    lineColor: "#007ABD",
    pointBgColor: "#fff",
    pointHoverBgColor: "#007ABD"
  }
];

function areaChartDataToDatasets(
  labels: string[],
  data?: Array<{ dt: string; value: number; group?: number }>
): DataSet[] {
  if (!data?.length) {
    return [];
  }
  const datasets: DataSet[] = [];
  for (let idx = 0; ; idx++) {
    const items = data.filter(x => (x.group ?? 0) === idx);
    if (!items.length) {
      break;
    }
    datasets.push({
      ...colorPairs[idx],
      data: items.map(x => ({
        date: parseISO(x.dt),
        value: x.value
      })),
      label: labels[idx] ?? "Unknown"
    });
  }
  return datasets;
}

const CustomTooltip: React.FC<TooltipProps> = ({
  top,
  left,
  rows,
  datasetLabels
}) => {
  const style = React.useMemo(
    () => ({
      top,
      left
    }),
    [top, left]
  );
  if (!rows.length) {
    return null;
  }
  return (
    <div
      className="absolute bg-cream-200 border-2 border-cream-500 shadow-default pointer-events-none p-[16px] rounded-[4px]"
      style={style}
    >
      <div className="inputLabel">{format(rows[0].date, "MMM d, yyyy")}</div>
      {rows.map((row, idx) => (
        <div key={idx} className="paragraph flex gap-[4px] pt-[8px]">
          <span className="font-semibold">
            {row.value === Math.round(row.value)
              ? row.value.toFixed(0)
              : row.value.toFixed(1)}
          </span>
          {datasetLabels[idx]}
        </div>
      ))}
    </div>
  );
};

export const DashboardAreaChart: React.FC<DashboardAreaChartProps> = ({
  apiQuery,
  data,
  label,
  xTicksCB,
  cumulative
}) => {
  const { user } = useRequiredAuthContext();

  const datasets = React.useMemo(
    () => areaChartDataToDatasets(label.split(","), data),
    [data, label]
  );

  const fromDt = React.useMemo(
    () =>
      min(
        [parseISO(apiQuery.fromDt)].concat(
          datasets.flatMap(dataset => dataset.data.map(row => row.date))
        )
      ),
    [apiQuery.fromDt, datasets]
  );

  const tillDt = React.useMemo(
    () =>
      max(
        [parseISO(apiQuery.tillDt)].concat(
          datasets.flatMap(dataset => dataset.data.map(row => row.date))
        )
      ),
    [apiQuery.tillDt, datasets]
  );

  return (
    <AreaChart
      className="h-[110px] relative"
      from={fromDt}
      to={tillDt}
      scale={apiQueryScaleToChartScale(apiQuery.scale)}
      datasets={datasets}
      isoWeekday={user.weekStart === 1}
      fontFamily="TT Commons"
      tooltip={CustomTooltip}
      xAxisShowTicks={false}
      yAxisShowTicks={false}
      yAxisPosition="right"
      xTicksCB={xTicksCB}
      cumulative={cumulative}
    />
  );
};
