import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@material-ui/styles/withStyles/withStyles";

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#fff",
    color: "#151D34",
    boxShadow: `0px 0px 17px rgba(0, 0, 0, 0.14)`,
    fontSize: 16,
    textAlign: `center`
  },
  arrow: {
    color: "#fff"
  }
}))(Tooltip);

export default LightTooltip;
