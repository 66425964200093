import { FieldError } from "@goguardian/pdt-component-library";
import classnames from "classnames";
import React from "react";
import { useFormContext } from "react-hook-form";

import { IInputCommonProps } from "../FormsCommon";

interface IInputProps extends IInputCommonProps {
  name: string;
  type?: "number" | "text" | "email" | "password";
  aIassistant?: () => void;
  inputClassname?: string;
  handleFocus?: () => void;
  handleBlur?: () => void;
  handleKeyDown?: (e: React.KeyboardEvent) => void;
  testid?: React.InputTestID;
}

export const Input: React.FC<IInputProps> = ({
  name,
  type,
  disabled,
  required,
  inputClassname,
  testid,
  ...props
}) => {
  const { label, description, className } = props;

  const {
    errors,
    formState: { isSubmitting },
    register,
    watch
  } = useFormContext();

  const value = watch(name);
  const error = errors[name];
  const isDisabled = disabled || isSubmitting;

  return (
    <div className={className}>
      {label && (
        <label
          className={classnames("inputLabel", isDisabled && "text-gray-600")}
        >
          {label}
        </label>
      )}
      {description && (
        <div
          className={classnames(
            "explanatoryText mt-[-4px] mb-[8px]",
            isDisabled && "text-gray-600"
          )}
        >
          {description}
        </div>
      )}
      <input
        defaultValue={value}
        className={classnames(
          "formInput formInputBorderStyle block w-full mt-[8px]",
          inputClassname,
          error && "mb-[8px]",
          isDisabled && " text-gray-600 cursor-not-allowed"
        )}
        data-testid={testid}
        aria-invalid={error}
        type={type}
        name={name}
        ref={register({
          required,
          min: name === "day" ? 1 : undefined,
          max: name === "day" ? 31 : undefined
        })}
      />

      {error && name !== "day" && (
        <FieldError
          className="fill-current"
          error={
            error.type === "custom" ? error.message : "This field is required"
          }
        />
      )}
    </div>
  );
};
