import React from "react";

import { useRequiredAuthContext } from "~/components/auth/common";
import { WidgetContentNew } from "~/components/core/WidgetCard/WidgetCardContent";
import { getPreviousPeriod } from "~/constants/filters";
import ROUTES from "~/constants/routes";
import { useReduxDispatch, useReduxSelector } from "~/redux/hooks";
import {
  setChartsLoading,
  setDataSetLength,
  setXAxisLabels
} from "~/redux/reducers/charts";
import { useAuthenticatedFetch } from "~/utils/http";
import { trackEvent } from "~/utils/segment";
import { struct } from "~/utils/struct";

import { DashboardAreaChart } from "../AreaChart";
import { createDashboardLazyWidgetNew } from "../createDashboardLazyWidgetNew";
import {
  ChartXAxisLabels,
  DashboardChartInfo
} from "../DashboardChartComponents";
import { useDashboardQueryState } from "../dashboardQueryState";

const DashboardActiveStudentsChart = createDashboardLazyWidgetNew({
  displayName: "DashboardActiveStudentsChart",
  useRender: (apiQuery, resetFilters) => {
    const dispatch = useReduxDispatch();
    const [{ period }] = useDashboardQueryState();
    const { schoolYearStartDate } = useRequiredAuthContext();

    const { cumulative } = useReduxSelector(state => ({
      cumulative: state.charts.cumulative
    }));

    const { data, loading, error } = useAuthenticatedFetch(
      "/api/v1/charts/active_students/",
      apiQuery,
      true
    );
    const prevApiQuery = React.useMemo(
      () => getPreviousPeriod(apiQuery, period, schoolYearStartDate),
      [apiQuery, period, schoolYearStartDate]
    );

    const handleXAxisCB = React.useCallback(
      (values: IXAxisLabel[], chartData: IChartData[]) => {
        const labels = JSON.parse(JSON.stringify(values));
        dispatch(setXAxisLabels(labels));
        if (!cumulative && chartData[0] && chartData[0].data.length) {
          dispatch(setDataSetLength(chartData[0].data.length));
        }
      },
      [dispatch, cumulative]
    );

    React.useEffect(() => {
      dispatch(setChartsLoading(loading));
    }, [loading, dispatch]);

    React.useEffect(() => {
      trackEvent("load_active_students_chart", undefined);
    }, []);

    return (
      <>
        <DashboardChartInfo
          total={
            data?.length ? data.reduce((a, c) => a + c.value, 0) : undefined
          }
          title="Unique active students"
          clarification={
            <div>
              <span className="font-semibold">Unique active students</span> have
              completed at least one live tutoring session or Writing Lab.
            </div>
          }
          link={!!data?.length ? ROUTES.students : undefined}
          prevApiQuery={prevApiQuery}
          endpoint="active_students"
          testid={struct.adminDash.dashboard.generalUsage.activeStudentsChart}
        />

        <WidgetContentNew
          loading={loading}
          hasData={!!data?.length}
          error={error}
          resetFilters={resetFilters}
          className="w-[80%] !h-[110px] !m-0"
          testid={struct.adminDash.dashboard.generalUsage.activeStudentsChart}
        >
          <div className="h-auto w-[80%] graph">
            <DashboardAreaChart
              apiQuery={apiQuery}
              data={data}
              label="Students"
              xTicksCB={handleXAxisCB}
              cumulative={cumulative}
            />
            <ChartXAxisLabels
              id="activeStudents"
              className="hidden w-full graphs-show"
            />
          </div>
        </WidgetContentNew>
      </>
    );
  }
});

export default DashboardActiveStudentsChart;
