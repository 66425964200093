import React from "react";

import { useRequiredAuthContext } from "~/components/auth/common";
import { WidgetContentNew } from "~/components/core/WidgetCard/WidgetCardContent";
import { getPreviousPeriod } from "~/constants/filters";
import { useReduxSelector } from "~/redux/hooks";
import { useAuthenticatedFetch } from "~/utils/http";
import { localeFormat } from "~/utils/numbers";
import { trackEvent } from "~/utils/segment";
import { struct } from "~/utils/struct";

import { DashboardAreaChart } from "../AreaChart";
import { createDashboardLazyWidgetNew } from "../createDashboardLazyWidgetNew";
import {
  ChartXAxisLabels,
  DashboardChartInfo
} from "../DashboardChartComponents";
import { useDashboardQueryState } from "../dashboardQueryState";

const legendData = [
  { color: "#195E44", text: "Live tutoring hours" },
  { color: "#007ABD", text: "Writing lab hours" }
];

const DashboardLiveVsWritingLabChart = createDashboardLazyWidgetNew({
  displayName: "DashboardLiveVsWritingLabChart",
  useRender: (apiQuery, resetFilters) => {
    const cumulative = useReduxSelector(state => state.charts.cumulative);

    const [{ period }] = useDashboardQueryState();
    const { schoolYearStartDate } = useRequiredAuthContext();

    const prevApiQuery = React.useMemo(
      () => getPreviousPeriod(apiQuery, period, schoolYearStartDate),
      [apiQuery, period, schoolYearStartDate]
    );

    const [totals, setTotals] = React.useState<undefined | [number, number]>(
      undefined
    );

    const { data, loading, error } = useAuthenticatedFetch(
      "/api/v1/charts/comparison_sessions/",
      apiQuery,
      true
    );

    const handleXAxisCB = React.useCallback(
      (_values: IXAxisLabel[], chartDataSets: IChartData[]) => {
        if (!cumulative && chartDataSets[0] && chartDataSets[1]) {
          const llTotal = chartDataSets[0].data.reduce((a, c) => a + c.y, 0);
          const wlTotal = chartDataSets[1].data.reduce((a, c) => a + c.y, 0);
          setTotals([llTotal, wlTotal]);
        }
      },
      [cumulative]
    );

    React.useEffect(() => trackEvent("ll_v_wl_load_chart", undefined), []);

    return (
      <>
        <DashboardChartInfo
          total={
            data?.length ? data.reduce((a, c) => a + c.value, 0) : undefined
          }
          title="Total tutoring hours"
          legend={
            <div className="flex flex-col my-[8px]">
              {legendData.map(({ color, text }, index) => (
                <div className="flex items-center gap-[4px]" key={index}>
                  <div
                    className="h-[8px] w-[8px] rounded-full"
                    style={{ backgroundColor: color }}
                  />
                  {totals && (
                    <div className="inputLabel ml-[4px]">
                      {localeFormat(totals[index])}
                    </div>
                  )}
                  <div className="explanatoryText">{text}</div>
                </div>
              ))}
            </div>
          }
          prevApiQuery={prevApiQuery}
          endpoint="comparison_sessions"
          clarification={
            <div>
              <span className="font-semibold">Total tutoring hours</span> are
              sum of Live session hours and Writing Lab hours. Writing Lab hours
              are calculated from the length of each Writing Lab submission.
            </div>
          }
          testid={struct.adminDash.dashboard.writingLabs.lessonsVsWLChart}
        />

        <WidgetContentNew
          loading={loading}
          hasData={!!data?.length}
          error={error}
          resetFilters={resetFilters}
          className="w-[80%] !h-[110px] !m-0"
          testid={struct.adminDash.dashboard.writingLabs.lessonsVsWLChart}
        >
          <div className="h-[110px] w-[80%] graph">
            <DashboardAreaChart
              apiQuery={apiQuery}
              data={data}
              label="Live Lessons,Writing Labs"
              xTicksCB={handleXAxisCB}
              cumulative={cumulative}
            />
            <ChartXAxisLabels
              id="llVwl"
              className="hidden w-full graphs-show"
            />
          </div>
        </WidgetContentNew>
      </>
    );
  }
});

export default DashboardLiveVsWritingLabChart;
