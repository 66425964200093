import dayjs from "dayjs";
import * as React from "react";

import { useRequiredAuthContext } from "~/components/auth/common";
import ClarificationIcon from "~/components/core/ClarificationIcon";
import { WidgetContentNew } from "~/components/core/WidgetCard/WidgetCardContent";
import DashboardGuideContext from "~/components/dashboard/DashboardGuide/DashboardGuideContext";
import { useAuthenticatedFetch } from "~/utils/http";
import { trackEvent } from "~/utils/segment";
import { struct } from "~/utils/struct";

import { ConfidenceChange } from "./ConfidenceChange";
import { activeTooltip, DataDisplay, uniqueTooltip } from "./DataDisplay";
import { DonutChart } from "./DonutChart";

const DashboardHeader: React.FC = () => {
  const { headerRef } = React.useContext(DashboardGuideContext);
  const {
    selectedUmbrella: { umbrellaAccName },
    schoolYearStartDate
  } = useRequiredAuthContext();

  const { data, loading, error } = useAuthenticatedFetch(
    "/api/v1/new_stats/quick_facts/",
    {
      umbrellaAccountId: umbrellaAccName,
      fromDt: schoolYearStartDate
    },
    true
  );

  React.useEffect(() => trackEvent("load_quick_facts", undefined), []);

  const testid = struct.adminDash.dashboard.quickFacts;

  return (
    <div
      ref={headerRef}
      className="quickFacts bg-white py-[16px] mx-[32px] mb-[24px] rounded-[8px] border-2 border-cream-500 flex flex-col"
    >
      <div
        className="w-full flex justify-center items-center gap-[8px] h2 px-[10px]"
        data-testid={testid.schoolYear}
      >
        Total usage for School Year
        {` ${dayjs(schoolYearStartDate).format("YYYY")}-${dayjs(
          new Date(dayjs(schoolYearStartDate).year() + 1, 1, 1)
        ).format("YY")}`}
        <ClarificationIcon
          clarification={
            <div>
              The account’s school year dates are shown in your{" "}
              <span className="font-semibold">Settings</span> page within{" "}
              <span className="font-semibold">Account details</span>
            </div>
          }
          placement="right"
        />
      </div>

      <div className="my-[16px] border border-cream-300" />

      <WidgetContentNew
        hasData={!!data}
        loading={loading}
        error={error}
        className="h-[133px]"
      >
        <div className="quickFacts-container flex flex-row items-center px-[64px] justify-center gap-[64px]">
          <div className="quickFacts-students flex flex-col min-h-[130px] justify-between">
            <DataDisplay
              data={data?.studentsEnrolled ?? 0}
              testid={testid.studentsEnrolled}
              tooltip={uniqueTooltip}
              title="Unique visitors"
            />
            <DataDisplay
              data={data?.studentsActive ?? 0}
              title="Active students"
              tooltip={activeTooltip}
              testid={testid.studentsActive}
            />
          </div>

          <div className="flex flex-row  h-full items-center gap-0 sm:gap-[24px]">
            <DonutChart
              data={[data?.sessionHours ?? 0, data?.writingLabHours ?? 0]}
            />
            <div className="flex flex-col gap-[16px]">
              <div className="quickFact-dataContainer flex items-center gap-[16px]">
                <div className="w-[20px] h-[20px] rounded-full bg-chalkboard-600" />
                <div className="flex flex-row gap-[16px]">
                  <DataDisplay
                    data={data?.sessionHours ?? 0}
                    title="Live session hours"
                    testid={testid.sessionHours}
                  />
                  <DataDisplay
                    data={data?.sessionCount ?? 0}
                    title="Live sessions"
                    testid={testid.sessionCount}
                  />
                </div>
              </div>

              <div className="quickFact-dataContainer flex items-center gap-[16px]">
                <div className="w-[20px] h-[20px] rounded-full bg-blueSkies-400" />
                <div className="flex flex-row gap-[16px]">
                  <DataDisplay
                    data={data?.writingLabHours ?? 0}
                    title="Writing Lab hours"
                    testid={testid.writingLabHours}
                  />
                  <DataDisplay
                    data={data?.writingLabCount ?? 0}
                    title="Writing Lab reviews"
                    testid={testid.writingLabCount}
                  />
                </div>
              </div>
            </div>
          </div>

          <ConfidenceChange data={data} />
        </div>
      </WidgetContentNew>
    </div>
  );
};

export default DashboardHeader;
