import { AnalyticsBrowser } from "@segment/analytics-next";

export const segment = new AnalyticsBrowser();

type SegmentEvent = {
  change_umbrella_account: { umbrella: string };

  // dashboard
  graph_frequency_click: undefined;
  graph_cumulative_click: undefined;
  graph_enrolled_view_details_click: undefined;
  graph_active_view_details_click: undefined;
  graph_live_view_details_click: undefined;
  graph_wl_view_details_click: undefined;
  st_rating_live_click: undefined;
  st_rating_wl_click: undefined;
  student_rating_click: { rating: number };
  tutor_rating_click: { rating: number };
  student_quote_name_click: { name: string };
  power_user_name_click: { name: string };
  student_quote_view_ll_click: undefined;
  student_quote_view_wl_click: undefined;
  load_quick_facts: undefined;
  load_combined_stats: undefined;
  load_general_stats: undefined;
  load_total_hours_chart: undefined;
  load_total_sessions_chart: undefined;
  load_enrolled_students_widget: undefined;
  load_active_students_chart: undefined;
  load_student_ratings_ll_chart: undefined;
  load_student_ratings_wl_chart: undefined;
  load_tutor_ratings_ll_chart: undefined;
  load_student_quotes: undefined;
  load_power_users_hours: undefined;
  load_power_users_sessions: undefined;
  ll_load_general_stats: undefined;
  ll_load_subject_stats_chart: undefined;
  ll_subject_stats_change_grade: { grade: string };
  ll_subject_stats_view_sessions: { url: string };
  ll_subject_stats_view_all_sessions: undefined;
  ll_subject_stats_reset: undefined;
  ll_load_hours_chart: undefined;
  ll_load_completed_chart: undefined;
  wl_load_general_stats: undefined;
  wl_load_hours_chart: undefined;
  wl_load_completed_chart: undefined;
  ll_v_wl_load_chart: undefined;
  change_date_range: { type: string; from?: string; to?: string };
  change_scale_range: { type: string };
  change_moderation_filter: { type: string };
  change_subject_filter: { subject: string };
  clear_filter: { filter: string };
  clear_all_filters: undefined;
  reset_all_filters: undefined;
  view_resources: undefined;
  email_support: undefined;

  // lessons
  lessons_change_moderation_filter: { type: string };
  lessons_change_subject_filter: { subject: string };
  lessons_search: { query: string };
  lessons_change_date_range: { type: string; from?: string; to?: string };
  live_tutor_rating_filter_click: { rating: string };
  live_student_rating_filter_click: { rating: string };
  lessons_export: undefined;
  lesson_details: { lessonId: number };
  lesson_clever_filter: { cleverFacet: string | null };
  lesson_ratings_popup: { lessonId: number };

  // wl
  wl_change_moderation_filter: { type: string };
  wl_search: { query: string };
  wl_change_date_range: { type: string; from?: string; to?: string };
  wl_student_rating_filter_click: { rating: string };
  wl_export: undefined;
  wl_download_paper: { id: number };
  wl_paper_details: { id: number };
  wl_paper_feedback: { id: number };
  wl_clever_filter: { cleverFacet: string | null };

  // students
  students_search: { query: string };
  students_export: undefined;
  students_minutes_filter: { minutes: number };
  students_clever_filter: { cleverFacet: string | null };
  student_details_popup: { studentId: number };
  student_add_minutes_popup: { studentId: number };
  student_add_minutes: { studentId: number; minutes: number };
};

export function trackEvent<T extends keyof SegmentEvent>(
  event: T,
  properties: SegmentEvent[T]
): void {
  segment.track(event, properties);
}
