import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import zIndex from "@material-ui/core/styles/zIndex";

import { default as Theme, Overrides } from "~/declarations/theme";

export const theme: Theme = {
  breakpoints: createBreakpoints({ unit: "px" }),
  typography: {
    fontFamily: `TT Commons, "sans-serif"`
  },
  palette: {
    common: {
      white: "#ffffff"
    },
    primary: {
      main: "#067BC2"
    },
    error: {
      main: "#FF0000"
    }
  },
  zIndex
};

const overrides: Overrides = {
  ...(typeof theme?.overrides === "object" ? theme?.overrides : {}),
  MuiPaper: {
    rounded: {
      borderRadius: "24px"
    }
  },
  MuiDialog: {
    paper: {
      borderRadius: "4px"
    }
  },
  MuiInput: {
    root: {
      "&$disabled": {
        color: "#262626"
      }
    },
    underline: {
      "&:before": {
        borderBottom: `1px solid #9D9D9D`
      }
    }
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: 25,
      "& > fieldset": {
        borderColor: "#9D9D9D"
      }
    }
  },
  MuiInputLabel: {
    root: {
      fontSize: "12px",
      textTransform: "uppercase"
    }
  },
  MuiTableCell: {
    root: {
      borderBottom: "unset"
    },
    head: {
      color: "#555555",
      fontSize: "10px",
      textTransform: "uppercase",
      border: `1px solid #9D9D9D`,
      borderWidth: "1px 0 1px 0"
    }
  },
  MuiSelect: {
    root: {
      textTransform: "uppercase",
      marginRight: "15px"
    }
  },
  MuiPopover: {
    paper: {
      borderRadius: "5px"
    }
  },
  MuiAutocomplete: {
    root: {
      textTransform: "uppercase"
    },
    popper: {
      marginTop: "4px"
    },
    paper: {
      borderRadius: "4px",
      textTransform: "uppercase",
      boxShadow: `0px 0px 17px rgba(0, 0, 0, 0.14)`
    },
    inputRoot: {
      "& input": {
        textTransform: "uppercase",
        fontSize: "13px"
      },
      "&.MuiOutlinedInput-root": {
        padding: "2px 9px"
      }
    },
    listbox: {
      padding: 0
    },
    option: {
      borderBottom: `1px solid #9D9D9D`
    }
  }
};

export default createMuiTheme({ ...theme, overrides });
