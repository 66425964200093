import * as React from "react";

import Blocks1 from "../../../assets/images/blocks_1.svg";
import Blocks2 from "../../../assets/images/blocks_2.svg";
import Logo from "../../../assets/images/Logo_PDT_cream.svg";
import { TestIDUtils } from "../common/TestIdUtils";

interface Props {
  children: React.ReactElement | React.ReactElement[] | string;
}

const AuthLayout: React.FC<Props> = ({ children }) => {
  return (
    <>
      <main className="relative flex sm:items-center sm:justify-center w-screen h-screen bg-cream-100 sm:bg-chalkboard-700 sm:min-w-[600px] sm:min-h-[950px]">
        <header className="absolute top-0 sm:top-[36px] left-0 sm:left-[39px] min-w-full sm:min-w-0 z-10 bg-chalkboard-700 py-[24px] sm:p-0 pl-[40px]">
          <Logo className="h-[38px] w-[226px]" />
          <div className="ml-[34px] explanatoryText !text-cream-100">
            Formerly TutorMe
          </div>
          <div className="fieldLabel ml-[34px] mt-[8px] !text-cream-100">
            Admin Dashboard
          </div>
        </header>

        <div className="z-10 w-full sm:w-[472px] overflow-hidden px-[40px] py-[32px] relative bg-cream-100 sm:rounded-[4px] sm:shadow-default mt-[130px] sm:mt-0">
          {children}
        </div>
        <Blocks1 className="hidden sm:block absolute top-0 right-0 h-[240px] w-[240px]" />
        <Blocks2 className="hidden sm:block absolute bottom-0 left-0 h-[240px] w-[240px]" />
      </main>

      <TestIDUtils />
    </>
  );
};

export default AuthLayout;
