import classNames from "classnames";
import * as React from "react";

import ClarificationIcon from "~/components/core/ClarificationIcon";
import { localeFormat } from "~/utils/numbers";

export const DataDisplay: React.FC<{
  data: number;
  title: string;
  tooltip?: string | JSX.Element;
  testid?: React.TestID;
}> = ({ data, title, tooltip, testid }) => {
  return (
    <div className="flex flex-col whitespace-nowrap">
      <div
        className={classNames(
          "h2",
          !!tooltip && "!text-[32px] !leading-[32px]"
        )}
        data-testid={testid}
      >
        {localeFormat(data) || "—"}
      </div>
      <div className="flex items-center gap-[4px] paragraph">
        {title}
        {tooltip && (
          <ClarificationIcon clarification={tooltip} placement="right" />
        )}
      </div>
    </div>
  );
};

export const uniqueTooltip = (
  <div>
    <span className="font-semibold">Unique visitors</span> have logged into Pear
    Deck Tutor at least once
  </div>
);
export const activeTooltip = (
  <div>
    <span className="font-semibold">Active students</span> have completed at
    least one live tutoring session or Writing Lab
  </div>
);
