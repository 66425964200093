import { ButtonLink } from "@goguardian/pdt-component-library";
import React from "react";

import { useRequiredAuthContext } from "~/components/auth/common";
import ClarificationIcon from "~/components/core/ClarificationIcon";
import { WidgetContentNew } from "~/components/core/WidgetCard/WidgetCardContent";
import ROUTES from "~/constants/routes";
import { useAuthenticatedFetch } from "~/utils/http";
import { localeFormat } from "~/utils/numbers";
import { schoolYearFilterLabel } from "~/utils/schoolYearHelpers";
import { trackEvent } from "~/utils/segment";
import { struct } from "~/utils/struct";

import { createDashboardLazyWidgetNew } from "../createDashboardLazyWidgetNew";
import DashboardContext from "../DashboardContext";

const DashboardEnrolledStudents = createDashboardLazyWidgetNew({
  displayName: "DashboardEnrolledStudents",
  useRender: (apiQuery, resetFilters) => {
    const { schoolYearStartDate } = useRequiredAuthContext();
    const { selectedPeriod } = React.useContext(DashboardContext);
    const selectedPeriodTitle = selectedPeriod?.title
      ? schoolYearFilterLabel(selectedPeriod.title, schoolYearStartDate)
      : "";

    const { data, loading, error } = useAuthenticatedFetch(
      "/api/v1/new_stats/students_enrolled/",
      apiQuery,
      true
    );

    React.useEffect(
      () => trackEvent("load_enrolled_students_widget", undefined),
      []
    );

    return (
      <>
        <WidgetContentNew
          loading={loading}
          hasData={!!data}
          error={error}
          resetFilters={resetFilters}
          className="w-full !h-[110px] !m-0"
        >
          <div className="flex gap-[16px] items-center w-full bg-cream-200 rounded-[8px] border-2 border-cream-300 p-[16px]">
            <div
              className="h2 line-clamp-1 max-w-[100%] !text-[32px] !leading-[32px] h-[32px]"
              data-testid={
                struct.adminDash.dashboard.generalUsage.enrolledStudentsCard
                  .total
              }
            >
              {localeFormat(data?.studentsEnrolled || 0)}
            </div>

            <div className="flex flex-col gap-[4px]">
              <div className="paragraph flex gap-[4px] items-center">
                Unique visitors
                <ClarificationIcon
                  clarification={
                    <div>
                      <span className="font-semibold">Unique students</span>{" "}
                      visited the platform at least once during the selected
                      timeframe.
                    </div>
                  }
                  placement="right"
                />
              </div>
              <div className="paragraph flex gap-[8px] items-center">
                <div className="!text-gray-600 explanatoryText">
                  {selectedPeriodTitle}
                </div>
                <ButtonLink
                  variant="wrapper"
                  href={ROUTES.students}
                  className="paragraph link"
                  action={() =>
                    trackEvent("graph_enrolled_view_details_click", undefined)
                  }
                  data-testid={
                    struct.adminDash.dashboard.generalUsage.enrolledStudentsCard
                      .viewDetails
                  }
                >
                  View details
                </ButtonLink>
              </div>
            </div>
          </div>
        </WidgetContentNew>
      </>
    );
  }
});

export default DashboardEnrolledStudents;
