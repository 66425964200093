import { Button, ButtonLink } from "@goguardian/pdt-component-library";
import classnames from "classnames";
import * as React from "react";

import { useRequiredAuthContext } from "~/components/auth/common";
import { getResourcesLink } from "~/constants/common";
import { trackEvent } from "~/utils/segment";

interface Props {
  title?: string;
  description?: string;
  className?: string;
  resetFilters?: () => void;
  isDashboard?: boolean;
  testid?: {
    range: React.TestID;
    noData?: React.TestID;
    resetFilters?: React.ButtonTestID;
    resources?: React.ButtonTestID;
    studentById?: React.ButtonTestID;
    error: React.TestID;
  };
}

const VoidPlaceholder: React.FC<Props> = ({
  title = "No usage yet",
  description = "Try filtering with a broader time frame, or check out our resources for getting students started with Pear Deck Tutor.",
  className,
  resetFilters,
  isDashboard,
  testid
}: Props) => {
  const { selectedUmbrella } = useRequiredAuthContext();

  const handleReset = () => {
    if (resetFilters) {
      resetFilters();
      trackEvent("reset_all_filters", undefined);
    }
  };

  const trackResources = () => trackEvent("view_resources", undefined);

  return (
    <div
      className={classnames(
        className,
        isDashboard ? "gap-[8px]" : "gap-[16px] border-t border-cream-300",
        "flex flex-col items-center justify-center text-center h-full"
      )}
      data-testid={testid?.noData}
    >
      <div className={classnames(!isDashboard && "flex flex-col gap-[16px]")}>
        <div className="subtitle1">{title}</div>
        <div
          className={classnames("paragraph", !isDashboard && "max-w-[434px]")}
        >
          {description}
        </div>
      </div>
      <div
        className={classnames(
          isDashboard
            ? "flex flex-row gap-[16px]"
            : "flex flex-col items-center gap-[8px]"
        )}
      >
        <Button
          variant="secondary"
          className={classnames(!isDashboard && "mt-[16px]")}
          action={handleReset}
          data-testid={testid?.resetFilters}
        >
          Reset all filters
        </Button>
        <ButtonLink
          href={getResourcesLink(selectedUmbrella.partnerType)}
          variant="text"
          target="_blank"
          rel="noopener noreferrer"
          action={trackResources}
          data-testid={testid?.resources}
        >
          Help your students get started
        </ButtonLink>
      </div>
    </div>
  );
};

export default VoidPlaceholder;
