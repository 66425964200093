import { Button } from "@goguardian/pdt-component-library";
import CheckIcon from "@material-design-icons/svg/filled/check.svg";
import classnames from "classnames";
import React from "react";

export const SegmentedButtons: React.FC<{
  on: boolean;
  toggle: (on: boolean) => void;
  titlePrimary: string;
  titleSecondary: string;
  className?: string;
  testidPrimary?: React.ButtonTestID;
  testidSecondary?: React.ButtonTestID;
}> = ({
  on,
  toggle,
  titlePrimary,
  titleSecondary,
  className,
  testidPrimary,
  testidSecondary
}) => {
  return (
    <div className={classnames("flex", className)}>
      <Button
        variant="wrapper"
        className={classnames(
          segmentedButtonClass,
          "rounded-r-none",
          on ? segmentedActiveClass : "border-r-0"
        )}
        action={() => toggle(true)}
        data-testid={testidPrimary}
      >
        {on && <CheckIcon className="mr-[8px] scale-[75%]" aria-hidden />}
        {titlePrimary}
      </Button>
      <Button
        variant="wrapper"
        className={classnames(
          segmentedButtonClass,
          "rounded-l-none",
          !on ? segmentedActiveClass : "border-l-0"
        )}
        action={() => toggle(false)}
        data-testid={testidSecondary}
      >
        {!on && <CheckIcon className="mr-[8px] scale-[75%]" aria-hidden />}
        {titleSecondary}
      </Button>
    </div>
  );
};

const segmentedButtonClass = classnames(
  "h-[40px] px-[12px] flex flex-grow-0 items-center rounded-[4px] paragraph",
  "border border-navy-900 bg-white hover:bg-chalkboard-100",
  "focus-visible:bg-chalkboard-200 focus-visible:!ring-0 focus-visible:!ring-offset-0"
);
const segmentedActiveClass =
  "border-[2px] border-chalkboard-700 bg-chalkboard-100 buttonLabel";
