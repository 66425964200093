import * as React from "react";

import { WidgetContentNew } from "~/components/core/WidgetCard/WidgetCardContent";
import { useAuthenticatedFetch } from "~/utils/http";
import { trackEvent } from "~/utils/segment";
import { struct } from "~/utils/struct";

import { createDashboardLazyWidget } from "../createDashboardLazyWidget";
import DashboardContext from "../DashboardContext";
import { Quotes } from "./Quotes";

const DashboardStudentQuotes = createDashboardLazyWidget({
  displayName: "DashboardStudentQuotes",
  className: "flex-1 p-[24px]",
  testid: struct.adminDash.dashboard.quotes.range,
  useRender: (apiQuery, resetFilters) => {
    const { selectedPeriod } = React.useContext(DashboardContext);

    const sessionQuotes = useAuthenticatedFetch(
      "/api/v1/new_stats/session_quotes/",
      { ...apiQuery },
      true
    );

    const wlQuotes = useAuthenticatedFetch(
      "/api/v1/new_stats/wl_quotes/",
      { ...apiQuery },
      true
    );

    React.useEffect(() => {
      trackEvent("load_student_quotes", undefined);
    }, []);

    return (
      <div className="flex flex-col">
        <div className="flex items-center gap-[16px]">
          <div className="h2 line-clamp-1 max-w-[100%]">
            Highlighted student quotes
          </div>
          <div
            className="!text-gray-600 explanatoryText"
            data-testid={struct.adminDash.dashboard.quotes.range}
          >
            {selectedPeriod?.title || ""}
          </div>
        </div>

        <div className="flex flex-row gap-[40px]">
          <div className={containerClassName}>
            <div className="inputLabel">Student quotes from Live Tutoring</div>
            <WidgetContentNew
              loading={sessionQuotes.loading}
              error={sessionQuotes.error}
              hasData={true}
              className="mt-[16px] h-[245px]"
            >
              <Quotes
                quotes={sessionQuotes.data || []}
                lessons
                resetFilters={resetFilters}
              />
            </WidgetContentNew>
          </div>

          <div className={containerClassName}>
            <div className="inputLabel">Student quotes from Writing Lab</div>
            <WidgetContentNew
              loading={wlQuotes.loading}
              error={wlQuotes.error}
              hasData={true}
              className="mt-[16px] h-[245px]"
            >
              <Quotes
                quotes={wlQuotes.data || []}
                lessons={false}
                resetFilters={resetFilters}
              />
            </WidgetContentNew>
          </div>
        </div>
      </div>
    );
  }
});

const containerClassName = "flex-[50%] flex-col min-h-[245px] mt-[24px]";

export default DashboardStudentQuotes;
