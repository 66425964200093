import classNames from "classnames";
import React from "react";
import { Doughnut } from "react-chartjs-2";

import { localeFormat } from "~/utils/numbers";
import { struct } from "~/utils/struct";

export const DonutChart: React.FC<{ data: number[] }> = ({ data }) => {
  const total = data[0] + data[1];
  return (
    <div className="relative">
      <Doughnut
        {...config}
        data={{
          datasets: [
            {
              data: data[0] || data[1] ? data : [1, 1],
              backgroundColor: ["#258A64", "#8DD7FF"]
            }
          ]
        }}
        width={145}
        height={145}
      />
      <div className="mt-[5px] paragraph text-center flex flex-col items-center justify-center z-[3] h-full w-full top-0 left-0 absolute rounded-full bg-transparent">
        <div
          className={classNames(
            "h2 !leading-[32px]",
            total >= 100000
              ? "!text-[18px]"
              : total >= 10000
              ? "!text-[24px]"
              : total >= 1000
              ? "!text-[28px]"
              : "!text-[32px]"
          )}
          data-testid={struct.adminDash.dashboard.quickFacts.totalHours}
        >
          {localeFormat(total) || "—"}
        </div>
        Tutoring
        <br /> hours
      </div>
    </div>
  );
};

const config = {
  type: "doughnut",
  options: {
    cutoutPercentage: 80,
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      enabled: false
    },
    hover: { mode: null },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    }
  }
};
