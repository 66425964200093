import { ButtonLink } from "@goguardian/pdt-component-library";
import { omit } from "ramda";
import React from "react";

import Rating from "~/components/core/Rating/Rating";
import { WidgetContentNew } from "~/components/core/WidgetCard/WidgetCardContent";
import ROUTES from "~/constants/routes";
import { useAuthenticatedFetch } from "~/utils/http";
import { trackEvent } from "~/utils/segment";
import { struct } from "~/utils/struct";

import { createDashboardLazyWidget } from "../createDashboardLazyWidget";

const DashboardPowerUsers = createDashboardLazyWidget({
  displayName: "DashboardPowerUsers",
  caption: "Power users",
  testid: struct.adminDash.dashboard.generalUsage.powerUsersSessionsChart,
  useRender: (apiQuery, handleReset) => {
    const { data, loading, error } = useAuthenticatedFetch(
      "/api/v1/new_stats/combined/power_sessions/",
      omit(["scale"], apiQuery),
      true
    );

    React.useEffect(() => {
      trackEvent("load_power_users_sessions", undefined);
    }, []);

    return (
      <WidgetContentNew
        loading={loading}
        error={error}
        hasData={data && data.length > 0 ? true : undefined}
        className="min-h-[245px] mt-[16px]"
        resetFilters={handleReset}
        testid={struct.adminDash.dashboard.generalUsage.powerUsersSessionsChart}
      >
        <table className="w-[calc(100%-64px)] table-fixed mx-auto mb-[24px]">
          <TableHeader />

          <tbody className="text-right normal-case align-bottom paragraph">
            {data?.map(user => (
              <tr
                key={user.userId}
                className="h-[40px]"
                data-testid={struct.adminDash.dashboard.generalUsage.powerUsersSessionsChart.student(
                  user.userId
                )}
              >
                <td className="text-left truncate">
                  <UserLink name={user.displayName} />
                </td>
                <td>{user.sessionsTaken}</td>
                <td>{user.hoursTaken?.toFixed(2)}</td>
                <td className="flex justify-end">
                  {user.averageStudentRating > 0 ? (
                    <Rating
                      rating={user.averageStudentRating}
                      className="h-[40px] items-end"
                    />
                  ) : (
                    <span className="h-[40px] items-end flex">N/A</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </WidgetContentNew>
    );
  }
});

export default DashboardPowerUsers;

const TableHeader: React.FC = () => (
  <thead>
    <tr className="fieldLabel tracking-[.45px] text-right border-b border-cream-300">
      <th className="w-[30%] text-left">Student</th>
      <th className="w-[20%]">Sessions and reviews</th>
      <th className="w-[20%]">Hours</th>
      <th className="w-[30%]">Avg Tutor Rating of Student</th>
    </tr>
  </thead>
);

const UserLink: React.FC<{ name: string }> = ({ name }) => {
  const url = React.useMemo(() => {
    const params = new URLSearchParams({ search: name });
    return `${ROUTES.students}?${params.toString()}`;
  }, [name]);

  return (
    <ButtonLink
      variant="wrapper"
      className="paragraph link"
      href={url}
      action={() => trackEvent("power_user_name_click", { name })}
      data-testid={struct.adminDash.dashboard.generalUsage.powerUsersSessionsChart.studentButton(
        name
      )}
    >
      {name}
    </ButtonLink>
  );
};
