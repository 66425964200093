import Tooltip from "@material-ui/core/Tooltip";
import classNames from "classnames";
import * as React from "react";

import { DashboardApiQuery, useAuthenticatedFetch } from "~/utils/http";

interface IProps {
  currentTotal?: number;
  endpoint:
    | "active_students"
    | "live_sessions"
    | "wl_sessions"
    | "comparison_sessions";
  apiQuery: DashboardApiQuery;
  testid?: React.TestID;
}

export const ChartChange: React.FC<IProps> = ({
  currentTotal,
  endpoint,
  apiQuery,
  testid
}) => {
  const { data, loading } = useAuthenticatedFetch(
    `/api/v1/charts/${endpoint}/`,
    apiQuery,
    true
  );

  const change = React.useMemo(() => {
    const start = data?.reduce((acc, item) => acc + item.value, 0);
    const end = currentTotal;
    if (start === undefined || end === undefined) {
      return undefined;
    } else if (start === 0 || end === 0) {
      return undefined;
    } else {
      const change = Math.round(((end - start) / start) * 100);
      return change;
    }
  }, [currentTotal, data]);

  if (loading) {
    return null;
  }

  return (
    <Tooltip
      title="The percentage change in activity compared to the previous period."
      placement="right"
      classes={{
        tooltip:
          "bg-white paragraph font-normal shadow-default !p-[8px] rounded-4px border border-gray-200 text-left"
      }}
      enterTouchDelay={0}
    >
      <div
        className={classNames(
          "percent-change absolute top-[2px] w-fit flex items-center justify-center px-[12px] py-[4px] rounded-[8px] inputLabel cursor-default",
          !change &&
            "bg-navy-100 text-navy-800 hover:bg-navy-200 whitespace-nowrap !text-[16px]", // 0 or undefined
          change && change > 0 && "bg-kiwi-100 text-kiwi-800 hover:bg-kiwi-200",
          change &&
            change < 0 &&
            "bg-redApple-100 text-redApple-800 hover:bg-redApple-200",
          currentTotal &&
            (currentTotal > 1000
              ? "left-[75px]"
              : currentTotal > 100
              ? "left-[65px]"
              : currentTotal > 10
              ? "left-[45px]"
              : "left-[30px]")
        )}
        data-testid={testid}
      >
        {change === undefined
          ? `More data needed`
          : `${change >= 0 ? "+" : ""}${change}%`}
      </div>
    </Tooltip>
  );
};
