import { Button } from "@goguardian/pdt-component-library";
import ArrowIcon from "@material-design-icons/svg/outlined/keyboard_arrow_down.svg";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import classNames from "classnames";
import { Dayjs as DayjsType } from "dayjs";
import * as React from "react";

import { DATE_UNIT, MONTH_FORMAT } from "~/constants/dates";
import { struct } from "~/utils/struct";

import CustomRangePickerMonth from "./CustomRangePickerMonth";
import CustomRangePickerYear from "./CustomRangePickerYear";

export interface Props {
  month: DayjsType;
  onNext?: () => void;
  onBack?: () => void;
  onSwitch?: () => void;
  withControls?: boolean;
  menuOpen: boolean;
  currentView: DATE_UNIT;
  onSelectYear: (date: MaterialUiPickersDate) => void;
  onSelectMonth: (date: MaterialUiPickersDate) => void;
}

const CustomRangePickerToolbar: React.FC<Props> = ({
  onSwitch,
  onNext,
  onBack,
  month,
  withControls,
  menuOpen,
  currentView,
  onSelectYear,
  onSelectMonth
}) => {
  return (
    <div className="flex items-center justify-between inputLabel px-[10px] pb-[6px]">
      <div className="relative flex items-center">
        {month.format(MONTH_FORMAT)}
        {withControls && onSwitch && (
          <Button
            variant="wrapper"
            action={onSwitch}
            aria-label="Select year"
            data-testid={struct.adminDash.queryFilters.datePicker.yearSelect}
          >
            <ArrowIcon
              aria-hidden
              className={classNames(
                "w-[24px] h-[24px] flex-shrink-0",
                menuOpen && "transition-transform ease-in-out rotate-180"
              )}
            />
          </Button>
        )}
        {currentView === DATE_UNIT.year && (
          <CustomRangePickerYear initialValue={month} onChange={onSelectYear} />
        )}
        {currentView === DATE_UNIT.month && (
          <CustomRangePickerMonth
            initialValue={month}
            onChange={onSelectMonth}
          />
        )}
      </div>
      {withControls && onBack && onNext && (
        <div className="flex gap-[4px] items-center">
          <Button
            variant="wrapper"
            action={onBack}
            aria-label="Previous month"
            data-testid={struct.adminDash.queryFilters.datePicker.previousMonth}
          >
            <ArrowIcon
              aria-hidden
              className="w-[24px] h-[24px] flex-shrink-0 rotate-90"
            />
          </Button>
          <Button
            variant="wrapper"
            action={onNext}
            aria-label="Next month"
            data-testid={struct.adminDash.queryFilters.datePicker.nextMonth}
          >
            <ArrowIcon
              aria-hidden
              className="w-[24px] h-[24px] flex-shrink-0 rotate-[-90deg]"
            />
          </Button>
        </div>
      )}
    </div>
  );
};

export default CustomRangePickerToolbar;
